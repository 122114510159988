<template>
    <div>
        <header-portrate v-if="!is_mobile" />
        <exports-print ref="exportbar" v-if="!is_mobile" />
        <div v-if="is_mobile" style="padding:5px;background:#d7b100">
            <div class="text-center p-2" style="background:red;color:#fff;margin:auto 50px;" @click="$router.push({path: '/logout'})">
                {{ lang.logout }}
            </div>
        </div>
        <div>
            <TabsComp :tabs="tabs" v-if="!is_mobile" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-10px !important;`">
                <v-row class="mt-10">
                    <v-col
                        cols="12"
                        md="10"
                        sm="12">
                        <form autocomplete="off">
                            <v-row>
                                <v-col cols="12" md="2" sm="12">
                                    <label>اختر {{lang.start_date}}</label>
                                    <b-input-group>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                        <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>اختر {{lang.end_date}}</label>
                                    <b-input-group>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList"/>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                        <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                                    </b-input-group>
                                </v-col>
                                <!-- <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.mobile}}</label>
                                    <b-input-group>
                                        <b-form-input class="inborder" v-model="mobile" style="background:#efefef;" />
                                    </b-input-group>
                                </v-col> -->
                                <!-- <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.industrial}}</label>
                                    <b-input-group>
                                        <b-form-select class="selborder" v-model="industrial" :options="InDustr" style="" />
                                    </b-input-group>
                                </v-col> -->
                                <v-col cols="12" md="2" sm="12">
                                <label></label>
                                <b-input-group>
                                    <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getContracts()">{{lang.search}}</b-button>
                                </b-input-group>
                                </v-col>
                            </v-row>
                        </form>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" class="text-end">
                        <b-button
                            v-b-toggle.addContract
                            class="btn-sm btn "
                            @click="resetContr()"
                            style="margin-top:9px;width:165px;background:transparent;border:none !important;margin-left:-7px !important;"
                            id="multiply-button" 
                            >
                            <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_contract}}</div>
                        </b-button>
                    </v-col>
                    <v-col cols="12" :style="`direction: `+lang.dir+`;`">
                        <div  v-if="is_mobile">
                            <v-card v-for="(item,index) in tablerows" :style="`margin:5px;border:1px solid #000`" :key="index">
                                <v-card-title style="font-size:18px;color:#000 !important;">{{ item.customer_name }}</v-card-title>
                                <v-card-subtitle style="color:#000 !important;">{{ item.last_update }}</v-card-subtitle>
                                <v-card-text :style="`background:${item.next_action_bg} !important;color:${item.next_action_co} !important`">
                                    <div style="color:#000 !important;">{{lang.next_action}} : {{ item[`next_action_`+lang.langname] }}</div>
                                    <div style="color:#000 !important;">{{lang.next_action_date}} : {{ item.next_action_date }}</div>
                                </v-card-text>
                                <v-avatar rounded="0" :style="`width:100px;position:absolute;top:10px; left: 10px;background:${item.status_bg} !important;color:${item.status_co} !important`">
                                    {{ item[`status_`+lang.langname] }}
                                </v-avatar>
                                <v-card-actions class="text-center">
                                    <v-btn style="margin:auto;width:100px;background:blue;color:#fff !important" @click="openView(item.id)" v-b-toggle.view_crm>{{ lang.view }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </div>
                        <v-data-table
                            :headers="headers"
                            :items="tablerows"
                            :search="search"
                            :items-per-page="15"
                            width="100%"
                            id="MainTable"
                            hide-default-footer
                            fixed-header
                            v-if="!is_mobile"
                        >
                        <template slot="body.prepend">  
                            <tr>
                                <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-top:1px solid #000 !important;border-bottom :2px solid #bbb !important;" colspan="9">&#32;</td>
                                <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-bottom :2px solid #bbb !important;border-top:1px solid #000 !important;">&#32;</td>
                            </tr>
                        </template>
                            <template v-slot:item="row">
                                <tr>
                                    <td class="text-center" style="width:60px">{{ row.item.contract_id }}</td>
                                    <td class="text-center" style="width:60px">{{ row.item.start_date }}</td>
                                    <td class="text-center" style="width:60px">{{ row.item.expire_date }}</td>
                                    <td class="text-center" style="width:100px">{{ row.item.customer_contacts[0].mobile }}</td>
                                    <td class="text-center">{{ row.item.customer_name }}</td>
                                    <td class="text-center" style="width:60px">{{ row.item.contract_items.length }}</td>
                                    <td nowrap class="text-center" style="width:60px">{{ row.item.jobs_list.length }}</td>
                                    <td nowrap class="text-center" style="width:60px">{{ row.item.invoices_list.length }}</td>
                                    <td nowrap class="text-center" style="width:80px;">{{ row.item.cars.length }}</td>
                                    <td class="text-center" nowrap style="width:80px;">{{ row.item.total }}</td>
                                    <td class="text-center" style="width:80px;" >{{ row.item.vat }}</td>
                                    <td class="text-center" style="width:80px;" >{{ row.item.ftotal }}</td>
                                    <td
                                        style="
                                        cursor: pointer;
                                        width: 85px;
                                        border: 2px solid #bbb !important;
                                        border-top: none !important; 
                                        padding:3px;
                                        text-align: center;
                                        "
                                        class="blackBack"
                                    >
                                        <div @click="openView(row.item.contract_id)" v-b-toggle.car_contract_view>{{lang.view}}</div>
                                    </td>
                                </tr>
                            </template>
                            <template slot="body.append">
                                    <!-- <tr class="black--text tfoot" v-if="$store.state.licenseType.showCardNums">
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                        <td class="backBlack" style="font-size:1.2em;text-align:center;">{{lang.totals}}</td>
                                        <td class="backBlack" style="font-size:1.2em;text-align:center;">{{ sumField('parts') }}</td>
                                        <td class="backGreen" style="font-size:1.2em;text-align:center;">{{ sumField('ftotal') }}</td>
                                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('vat') }}</td>
                                        <td class="backGreen" style="font-size:1.2em;text-align:center;">{{ sumField('paid') }}</td>
                                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('remaining') }}</td>
                                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('expen') }}</td>
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                    </tr> -->
                            </template>
                        </v-data-table>
                        
                    </v-col>
                    <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">
                        <v-container>
                            <div class="text-center pt-2">
                            <v-pagination
                                v-model="page"
                                value="10"
                                :length="pageCount"
                                color="#000"
                                @input="getNext(page)"
                            ></v-pagination>
                            </div>
                        </v-container>
                    </v-col>
                </v-row>
            </v-row>
        </div>
        <CarContractView ref="CarContractView" />
        <addContract ref="addContract" />
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import ExportsPrint from '@/components/exports-print.vue'
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
import CarContractView from '@/components/crm/CarContractView.vue'
import addContract from '@/components/addContract.vue'
import axios from 'axios'


export default{
    name: 'NewCRM',
    components: {
        addContract,
        HeaderPortrate,
        Footer,
        ExportsPrint
        ,TabsComp,
        CarContractView
    },
    data() {
        return {
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear() - 1,
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            tablerows: [],
            search: '',
            page: 1,
            pageCount:0,
            staticOption: [],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            mobile: '',
            industrial: 1,
            cpage:0,
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        is_mobile: function(){
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        headers: function(){
            let t = [
                {
                    text: this.lang.contract_id,
                    align: 'center',
                    filterable: false,
                    value: 'contractid',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.start_date,
                    align: 'center',
                    filterable: false,
                    value: 'contractid',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.expire_date,
                    align: 'center',
                    filterable: false,
                    value: 'contractid',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.mobile,
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.customer_name,
                    align: 'center',
                    filterable: false,
                    value: 'customer_name',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: "عدد الاصناف",
                    align: 'center',
                    filterable: false,
                    value: 'project_type',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: 'كروت الصيانة',
                    align: 'center',
                    filterable: false,
                    value: 'last_maint_by',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: 'عدد الفواتير',
                    align: 'center',
                    filterable: false,
                    value: 'last_maint_by',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.cars_number,
                    align: 'center',
                    filterable: false,
                    value: 'city',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.total,
                    align: 'center',
                    filterable: false,
                    value: 'total',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.vat,
                    align: 'center',
                    filterable: false,
                    value: 'vat',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.ftotal,
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                    
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                    value: 'agent',
                    sortable: false,
                    class: 'pa-0 blackBack'
                },

            ];
            return t;
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        InDustr: function(){
            let t = [{text: '', value: 0}];
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'inDustrial'){
                    t.push({text: this.staticOption[i][`name_`+this.lang.langname],value: this.staticOption[i].value})
                }
            }
            return t;
        },
        Statics: function(){
            let t = {
                crmActions: {},
                crmStatus: {},
                crmType: {},
            };
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'crmActions'){
                    t.crmActions[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
                else if(this.staticOption[i].option_phrase == 'crmStatus'){
                    t.crmStatus[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
                else if(this.staticOption[i].option_phrase == 'crmType'){
                    t.crmType[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
            }

            return t;
        },
        tablesRow: function(){
            
            return this.tablerows
        }
    },
    methods: {
        resetContr(){
            this.$refs.addContract.resetAll(1);
        },
        getContracts(){
            const post = new FormData(); 
            post.append("type","getCarContracts");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[sdate]",this.sdate)
            post.append("data[edate]",this.edate)
            post.append('data[con_type]',1)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.tablerows = response.data.results.data;
                }
            })

        },
        openView(id){
            this.$refs.CarContractView.getContracts (id);
            this.$refs.CarContractView.resetAllValues ();
        },
        getall(type,id,ret){
            // return this.Statics[type][id][ret];
        },
        getNext(page){
            this.cpage = parseInt(page) - 1;
            this.getContracts();
        },
        
        getStaticOp(){
            
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            post.append('type','getStaticOptions')
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[test]','1')
            axios.post(this.$store.state.SAMCOTEC.r_path, post)
            .then((response) => {
                if(response && response.data){
                    this.staticOption = response.data.results.data;
                    this.users = response.data.results.users;
                    // this.getClients()
                }
            })

        }
    },
    created(){
        this.getStaticOp()
        this.getContracts()
    }
}
</script>